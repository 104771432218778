:root {
    --color-play-btn: rgba(23, 35, 34, 0.75);
    --color-play-btn-hover: rgb(127, 95, 0);
}

.Hero {
    background: var(--black);
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: var(--hero-height);
    position: relative;
    transition: padding-bottom 0.4s ease;
    width: 100%;

    @media (--sm-viewport) {
        padding-bottom: var(--hero-height-sm);

        &.is-playing {
            padding-bottom: var(--hero-height);
        }
    }

    @media (--md-viewport) {
        padding-bottom: var(--hero-height-md);

        &.is-playing {
            padding-bottom: var(--hero-height);
        }
    }

    .Modernizr-no-js & {
        padding-bottom: var(--hero-height) !important;
    }

    &.has-iframe-backup {

        @media (--lt-md-viewport) {
            padding-bottom: var(--hero-height-sm);
        }

        @media (--min-viewport) {
            padding-bottom: var(--hero-height);
        }

        .Hero-iframeCover,
        > iframe {
            display: none;
        }

        .Modernizr-no-touchevents & {

            @media (--md-viewport) {
                .Hero-iframeCover,
                > iframe {
                    display: block;
                }

                > img {
                    display: none;
                }
            }
        }
    }

    > div,
    > div > img,
    > img,
    > iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    > div > img,
    > iframe,
    > .wp-video {
        height: var(--hero-height);
        top: 50%;
        transform: translateY(-50%);
    }

    > iframe:-webkit-full-screen {
        transform: none;
    }

    img {
        object-fit: cover;
    }
}

.Hero-title {
    background: var(--black);
    color: var(--white);

    h2 {
        font-size: var(--font-size-xxxxxlg);
        line-height: 1;
        text-align: center;
        width: 80%;
    }
}

.Hero-iframePlay {
    background: var(--color-play-btn);
    border-radius: 5px;

    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease;
    width: 65px;

    svg {
        fill: var(--white);
        height: 20px;
        margin: 8px 0 0 2px;
        width: 20px;
    }

    .Modernizr-no-touchevents &:hover {
        background: var(--color-work);

        svg {
            fill: var(--color-play-btn-hover);
        }
    }
}

.Hero-iframeCover {
    cursor: pointer;
    transition: opacity 0.8s ease 0.1s;
    z-index: 1;

    .Hero.is-playing & {
        opacity: 0;
        pointer-events: none;
    }
}

.Hero-iframeClose {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.4s ease;
    z-index: 2;

    .Hero.is-playing & {
        opacity: 1;
        pointer-events: all;
    }
}
