.Footer {
    background: var(--light-grey);
    width: 100%;

    @media (--sm-viewport) {
        padding: 55px var(--gutter-sm) 58px;
    }

    @media (--md-viewport) {
        align-items: center;
        display: flex;
        font-size: var(--font-size-sm);
        height: var(--hero-height-md);
        padding: 0 var(--gutter-md);
        position: relative;
    }
}

.Footer-inner {
    align-items: flex-start;
    color: var(--black);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-sm);
    justify-content: space-between;
    margin: 0 auto;
    padding: var(--gutter);
    width: 100%;

    @media (--sm-viewport) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0;
    }

    h3 {

        @media (--sm-viewport) {
            margin-bottom: 6px;
        }
    }

    address,
    a,
    small {
        font-family: var(--font-light);
    }

    h3,
    small,
    address,
    a {
        font-size: var(--font-size-sm);
        line-height: 1.9;

        @media (--sm-viewport) {
            font-size: var(--font-size-xsm);
        }

        @media (--md-viewport) {
            font-size: var(--font-size-sm);
        }
    }
}

.Footer-social,
.Footer-contact {
    border-bottom: 1px solid var(--grey-0-5);
    margin: 20px 0;
    padding-bottom: 20px;
    width: 100%;

    @media (--sm-viewport) {
        border: 0;
        flex: none;
        margin: 0;
        min-width: 250px;
        padding: 0;
        width: auto;
    }

    @media (--md-viewport) {
        min-width: 280px;
    }
}

.Footer-contact {
    padding-bottom: 40px;

    @media (--sm-viewport) {
        order: -1;
        padding-bottom: 0;
    }
}

.Footer-legal {

    @media (--sm-viewport) {
        align-self: flex-end;
        flex-grow: 2;
        text-align: right;
    }
}

.Footer-backToTop {
    align-items: center;
    background: var(--white);
    display: flex;
    justify-content: center;
    padding: 30px 0;

    .Page--about &,
    .Page--blog &,
    .Page--jobs &,
    .Page--culture &,
    .Page--contact &,
    .Page--error404 & {
        display: none;
    }

    @media (--sm-viewport) {
        display: none;
    }
}
