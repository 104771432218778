/**
 * Fonts
 *
 * Local font files will be inlined by postcss-url
 */

@font-face {
    font-family: "Univers LT W01 55 Roman";
    font-style: normal;
    font-weight: 400;
    src: url("Fonts/7b95cb9a-a288-4405-97a0-13095f56a903.woff") format("woff");
}

@font-face {
    font-family: "Univers LT W01 45 Light";
    font-style: normal;
    font-weight: 200;
    src: url("Fonts/ecf89914-1896-43f6-a0a0-fe733d1db6e7.woff") format("woff");
}

/*@font-face {
    font-family: "Univers LT W01_65 Bold1475968";
    font-style: normal;
    font-weight: 700;
    src: url("Fonts/05a79c4b-9e9d-46e4-9d4b-610be05371c4.woff") format("woff");
}*/
