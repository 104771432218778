.Supplementary {
    width: 100%;

    li {
        display: flex;
        justify-content: center;
        margin-bottom: 2em;
        width: 100%;

        @media (--md-viewport) {
            margin-bottom: 6em;
        }

        &.align-left {

            @media (--md-viewport) {
                justify-content: flex-start;
            }
        }

        &.align-right {

            @media (--md-viewport) {
                justify-content: flex-end;
            }
        }
    }
}

.Supplementary-item {
    width: 100%;

    @media (--md-viewport) {
        width: 49%;

        &.size-medium {
            width: 66%;
        }

        &.size-large {
            width: 100%;
        }
    }

    &.instagram,
    &.vine {
        max-width: 480px;
    }

    h3 {
        font-size: var(--font-size-sm);
        margin-top: 0.5em;
    }
}

.Supplementary-embed {
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    .youtube &,
    .vimeo & {
        padding-bottom: 56.25%;

        > img,
        > iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .instagram &,
    .twitter & {
        padding-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

.Supplementary-image {
    > img {
        width: 100%;
    }
}
