.Copy {
    font-family: var(--font-light);
    font-size: var(--font-size-mdlg);
    font-weight: 200;
    line-height: 1.7;

    div,
    span {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
    }

    img {
        height: auto;
    }

    img,
    iframe {
        display: block;
        margin: 0;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }

    .wp-caption-text {
        font-family: var(--font-normal);
        font-size: var(--font-size-xxsm);
        margin-top: 12px;
    }

    p,
    div {
        margin: 0 0 1.2em;
    }

    .Page--work &,
    .Page--jobs & {
        line-height: 1.6;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: var(--font-normal);
        font-size: var(--font-size-mdlg);
        line-height: 1.7;
        margin: 1.2em 0 1.2em;
        position: relative;
    }

    blockquote p {
        font-style: italic;
        margin-left: 20px;
        position: relative;

        &::before {
            content: "“";
            margin-right: 0.1em;
        }

        &::after {
            content: "”";
            margin-left: 0;
            position: absolute;
        }
    }

    .asset-img-link {
        cursor: default;
        pointer-events: none;
    }

    a:not(.asset-img-link) {
        background-image: linear-gradient(var(--color-blog), var(--color-blog));
        background-position: 0 1.2em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        font-weight: normal;
        margin-bottom: -0.2em;
        padding-bottom: 0.2em;
        text-shadow:
            -1px -1px 0 var(--color-bg),
            1px -1px 0 var(--color-bg),
            -1px 1px 0 var(--color-bg),
            1px 1px 0 var(--color-bg);

        .Page--work & {
            background-image: linear-gradient(var(--color-work), var(--color-work));
        }

        .Page--about & {
            background-image: linear-gradient(var(--color-about), var(--color-about));
        }

        .Page--jobs & {
            background-image: linear-gradient(var(--color-jobs), var(--color-jobs));
        }

        .Page--blog & {
            background-image: linear-gradient(var(--color-blog), var(--color-blog));
        }

        .Page--culture & {
            background-image: linear-gradient(var(--color-culture), var(--color-culture));
        }

        .Page--contact & {
            background-image: linear-gradient(var(--color-contact), var(--color-contact));
        }

        .Page--error404 & {
            background-image: linear-gradient(var(--color-home), var(--color-home));
        }
    }

    b,
    strong {
        font-family: var(--font-normal);
        font-weight: 400;
    }

    u,
    span[style*="text-decoration"] {
        background-image: linear-gradient(var(--color-text), var(--color-text));
        background-position: 0 1.2em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        margin-bottom: -0.2em;
        padding-bottom: 0.2em;
        text-decoration: none !important;
    }

    .Page--error404 & {
        font-family: var(--font-light);
        font-size: var(--font-size-lg);
        text-align: center;

        @media (--md-viewport) {
            font-size: var(--font-size-xxlg);
        }

        b,
        strong {
            font-family: var(--font-normal);
            font-weight: 400;
        }
    }

    ol:not([class]),
    ul:not([class]) {
        list-style: disc inside none;
        margin: 0 0 1.2em;

        li {
            margin: 0.5em 0;
        }
    }

    ol:not([class]) {
        list-style-type: decimal;
    }
}
