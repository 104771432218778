.Filter {
    /*transition: transform 0.25s ease-out-cubic;*/
    width: 100%;
}

/*.Filter.is-hidden {
    pointer-events: none;
    transform: translateY(100%);
}*/

.Filter-nav {
    padding: 24px var(--gutter) 14px;

    ul {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    li {
        flex: none;
        padding: 0 30px;
        position: relative;

        &:not(:last-child)::after {
            color: var(--grey);
            content: "|";
            font-family: var(--font-light);
            font-size: var(--font-size-xlg);
            position: absolute;
            right: 0;
            top: -8px;
        }
    }

    a {
        font-family: var(--font-light);
        font-size: var(--font-size-sm);
    }

    /*.is-open & {
        pointer-events: all;
        transform: none;
        transform: translateY(-100%);
    }*/
}

.Filter-nav li.is-current a,
.Modernizr-no-touchevents .Filter-nav li a:hover {
    background-image: linear-gradient(var(--color-blog), var(--color-blog));
    background-position: 0 1.6em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    margin-bottom: -0.6em;
    padding-bottom: 0.6em;
}
