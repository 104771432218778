@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Loader {
    background: var(--white);
    display: none;
    padding: 100px 0;

    .Page--blog & {
        margin-bottom: 100px;
    }

    svg {
        animation: spin 0.5s linear infinite;
        height: 37px;
        transform-origin: 50% 21.2px;
        width: 31px;

        .Page--home & {
            fill: var(--color-home);
        }

        .Page--work & {
            fill: var(--color-work);
        }

        .Page--jobs & {
            fill: var(--color-jobs);
        }

        .Page--blog & {
            fill: var(--color-blog);
        }

        .Page--culture & {
            fill: var(--color-culture);
        }
    }

    &.is-visible {
        display: block;
    }
}
