.Burger {
    height: 15px;
    position: absolute;
    right: var(--gutter);
    top: 27.5px;
    /*top: 28px;*/
    transform: rotate(0);
    transition: 0.5s ease-in-out;
    width: 20px;
    z-index: 11;

    @media (--sm-viewport) {
        right: var(--gutter-sm);
    }

    @media (--md-viewport) {
        display: none;
    }

    &::before {
        /*background-color: rgba(255, 0, 0, 0.3);*/
        content: "";
        height: 300%;
        left: -70%;
        position: absolute;
        top: -100%;
        width: 240%;
    }

    .Modernizr-no-js & {
        display: none;
    }
}

.Burger span {
    background: var(--black);
    display: block;
    height: 3px;
    /*height: 2px;*/
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
        top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
        top: 6px;
    }

    &:nth-child(4) {
        top: 12px;
    }
}

.Burger.is-open {
    backface-visibility: hidden;
    position: fixed;

    span:nth-child(1) {
        left: 50%;
        top: 6px;
        width: 0;
    }

    span:nth-child(2) {
        transform: rotate(45deg);
    }

    span:nth-child(3) {
        transform: rotate(-45deg);
    }

    span:nth-child(4) {
        left: 50%;
        top: 6px;
        width: 0;
    }
}
