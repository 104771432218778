.HeaderImage {
    overflow: hidden;
    padding-bottom: var(--hero-height);
    position: relative;
    width: 100%;

    @media (--sm-viewport) {
        padding-bottom: var(--hero-height-sm);
    }

    @media (--md-viewport) {
        padding-bottom: var(--hero-height-md);
    }
}

.HeaderImage-img {
    display: none;
    height: 100%;
    left: 0;
    min-width: 100%;
    object-fit: cover;
    object-position: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.HeaderImage.has-mobile .HeaderImage-img.mobile {
    display: block;

    @media (--sm-viewport) {
        display: none;
    }
}

.HeaderImage.has-tablet .HeaderImage-img.tablet {

    @media (--sm-viewport) {
        display: block;
    }

    @media (--md-viewport) {
        display: none;
    }
}

.HeaderImage .HeaderImage-img.desktop {

    @media (--md-viewport) {
        display: block;
    }
}

.HeaderImage:not(.has-tablet) .HeaderImage-img.desktop {

    @media (--sm-viewport) {
        display: block;
    }
}

.HeaderImage:not(.has-mobile) .HeaderImage-img.desktop {

    @media (--min-viewport) {
        display: block;
    }
}
