:root {
    --home-pad-md: 73px;
    --home-pad-top: 18px;
    --home-pad-top-sm: 30px;
    --home-pad-top-md: 65px;
    --home-item-height: calc(100vh - var(--nav-height));
    --home-item-height-sm: calc(100vh - var(--nav-height));
    --home-item-height-md: var(--ratio-16-9);
}

.Home-featured {
    background: var(--black);
    color: var(--white);
    height: var(--home-item-height);
    position: relative;
    width: 100%;

    @media (--sm-viewport) {
        height: var(--home-item-height-sm);
    }

    @media (--md-viewport) {
        height: var(--home-item-height-md);
        max-height: calc(100vh - var(--nav-height) + 1px);
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .Modernizr-js & img:not([style]) {
        opacity: 0;
        transition: opacity 0.6s ease;

        &.is-loaded {
            opacity: 1;
        }
    }
}

.Home-featuredInner {
    left: var(--gutter);
    max-width: calc(100% - 38px);
    position: absolute;
    top: 0;
    width: 100%;

    @media (--sm-viewport) {
        left: var(--gutter-sm);
        width: 600px;
    }

    @media (--md-viewport) {
        left: var(--home-pad-md);
        max-width: none;
        width: 650px;
    }

    @media (--xlg-viewport) {
        width: 750px;
    }
}

.Home-title {
    font-family: var(--font-light);
    font-size: var(--font-size-lg);
    line-height: 1.2;
    margin-bottom: 28px;
    margin-top: var(--home-pad-top);
    position: relative;
    transition:
        opacity 1.4s ease-out-cubic 0.3s,
        transform 1.6s ease-out-cubic 0.2s;

    @media (--sm-viewport) {
        font-size: var(--font-size-xxlg);
        line-height: 1.4;
        margin-top: var(--home-pad-top-sm);
    }

    @media (--md-viewport) {
        font-size: var(--font-size-xxxlg);
        line-height: 1.3;
        margin-bottom: 22px;
        margin-top: var(--home-pad-top-md);
    }

    @media (--xlg-viewport) {
        font-size: var(--font-size-xxxmlg);
    }

    @media (--min-landscape) {
        margin-bottom: 20px;
    }

    .Modernizr-js & {
        opacity: 0;
        transform: translateY(8vw);
    }

    .Home-featuredInner.is-visible & {
        opacity: 1;
        transform: none;
    }
}

.Home-viewLink {
    background-image: linear-gradient(var(--color-home), var(--color-home));
    background-position: 0 1.7em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    display: inline-block;
    font-size: var(--font-size-sm);
    letter-spacing: 1px;
    margin-bottom: -0.7em;
    padding: 0 0 0.7em;
    position: relative;
    transition:
        opacity 0.8s ease-out-cubic 0.3s,
        transform 0.9s ease-out-cubic 0.2s;
    transition-delay: 1s;

    .Modernizr-js & {
        opacity: 0;
        transform: translateY(40px);
    }

    .Home-featuredInner.is-visible & {
        opacity: 1;
        transform: none;
    }
}

.Home-featured--wk {
    background: transparent;
    pointer-events: none;
}
