:root {
    --thumb-height-sm: 35.6vw;
    --thumb-height-md: 24.7vw;
    --thumb-height-lg: 23.5vw;
    --thumb-height-xlg: 22.5vw;
    --thumb-height-full-sm: 26.4vw;
    --thumb-height-full-md: 24.7vw;
    --thumb-height-full-lg: 23.5vw;
    --thumb-height-full-xlg: 22.6vw;
    --thumb-width-sm: 48.5%;
    --thumb-width-md: 32%;
    --thumb-margin-sm: 2.5vw;
    --thumb-margin-md: 1.8vw;
    --thumb-margin-lg: 1.8vw;
    --thumb-margin-xlg: 1.8vw;
}

.Thumb {
    /*border: 1px dashed red;*/
    margin-bottom: 20px;
    margin-left: var(--gutter);
    position: relative;
    width: calc(100% - var(--gutter) * 2);

    @media (--min-viewport) {
        left: 0 !important;
        position: relative !important;
        top: 0 !important;
    }

    @media (--sm-viewport) {
        flex: none;
        height: var(--thumb-height-sm); /* TEMP */
        margin-left: 0;
        width: var(--thumb-width-sm);
    }

    @media (--md-viewport) {
        height: var(--thumb-height-md); /* TEMP */
        width: var(--thumb-width-md);
    }

    @media (--lg-viewport) {
        height: var(--thumb-height-lg); /* TEMP */
    }

    @media (--xlg-viewport) {
        height: var(--thumb-height-xlg); /* TEMP */
    }

    &.standard {
        background: none;
    }

    h2,
    p {
        font-size: var(--font-size-xsm);
        letter-spacing: 1px;

        @media (--md-viewport) {
            font-size: var(--font-size-sm);
        }

        @media (--xlg-viewport) {
            font-size: 1vw;
        }
    }

    h2 {
        line-height: 1.6;
        margin-bottom: 3px;

        @media (--md-viewport) {
            margin-bottom: 0.2vw;
        }
    }

    p {
        font-family: var(--font-light);
        line-height: 1.5;
    }

    a {
        height: 100%;
        left: 0;
        position: absolute;
        text-indent: -9999px;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.Thumb.double-size,
.Thumb.double-height {
    /* double height + margin */

    @media (--sm-viewport) {
        height: calc(var(--thumb-height-sm) * 2 + var(--thumb-margin-sm));
    }

    @media (--md-viewport) {
        height: calc(var(--thumb-height-md) * 2 + var(--thumb-margin-md));
    }

    @media (--lg-viewport) {
        height: calc(var(--thumb-height-lg) * 2 + var(--thumb-margin-lg));
    }

    @media (--xlg-viewport) {
        height: calc(var(--thumb-height-xlg) * 2 + var(--thumb-margin-xlg));
    }
}

.Thumb.double-size {

    @media (--sm-viewport) {
        width: 100%;
    }

    @media (--md-viewport) {
        width: 66%;
    }
}

.Thumb.double-size .Thumb-info,
.Thumb.double-height .Thumb-info,
.Thumb.full-width .Thumb-info {
    margin: 0;
    padding: 11px var(--gutter) 55px;
}

.Thumb.double-size .Thumb-info {

    @media (--sm-viewport) {
        bottom: 0;
        left: 0;
        min-height: 24vw;
        padding: 21px 40px 5vw 24px;
        position: absolute;
    }

    @media (--md-viewport) {
        min-height: 19vw;
        /*padding: 21px 40px 5vw 24px;*/
        padding: 1.2vw 3vw 5vw var(--thumb-margin-md);
    }
}

.Thumb.double-height .Thumb-img {

    @media (--sm-viewport) {
        overflow: hidden;

        &::before {
            padding-bottom: 102%;
        }
    }
}

.Thumb.double-height .Thumb-info {

    @media (--sm-viewport) {
        bottom: 0;
        left: 0;
        padding: 16px 22px 5vw 24px;
        position: absolute;
    }

    @media (--md-viewport) {
        /*padding: 19px 17px 52px 21px;*/
        padding: 1.2vw 3vw 5vw var(--thumb-margin-md);
    }
}

.Thumb.full-width {

    @media (--sm-viewport) {
        display: flex;
        height: var(--thumb-height-full-sm);
        width: 100%;
    }

    @media (--md-viewport) {
        height: var(--thumb-height-full-md);
    }

    @media (--lg-viewport) {
        height: var(--thumb-height-full-lg);
    }

    @media (--xlg-viewport) {
        height: var(--thumb-height-full-xlg);
    }
}

.Thumb.full-width .Thumb-img {

    @media (--sm-viewport) {
        width: var(--thumb-width-sm);
    }

    @media (--md-viewport) {
        width: 49%;
    }
}

.Thumb.full-width .Thumb-info {

    @media (--sm-viewport) {
        bottom: 0;
        height: 100%;
        padding: 18px 24px 5vw 24px;
        position: absolute;
        right: 0;
        width: 51.5%;
    }

    @media (--md-viewport) {
        padding: 1.2vw 3vw 5vw var(--thumb-margin-md);
        width: 51%;
    }
}

.Thumb.standard .Thumb-info {
    background: none;
}

.Thumb.double-height,
.Thumb.double-size,
.Thumb.full-width {
    color: var(--white);

    @media (--min-viewport) {
        margin-left: 0;
        width: 100%;
    }

    h2 {
        background-image: linear-gradient(var(--white), var(--white));
        background-position: 0 1.7em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        bottom: 17px;
        font-size: var(--font-size-sm);
        letter-spacing: 1px;
        margin-bottom: -0.7em;
        max-width: 85%;
        padding-bottom: 0.7em;
        position: absolute;

        @media (--sm-viewport) {
            bottom: 25px;
        }

        @media (--md-viewport) {
            /*bottom: 30px;*/
            bottom: 1.8vw;
        }

        @media (--xxlg-viewport) {
            font-size: var(--font-size-lgmd);
            letter-spacing: 1.5px;
        }
    }

    p {
        font-family: var(--font-normal);
        font-size: var(--font-size-md);
        height: auto;
        letter-spacing: 0.02em;

        @media (--sm-viewport) {
            font-family: var(--font-light);
            font-size: 2.2vw;
        }
    }
}

.Thumb-info {
    margin-top: 8px;
    width: 100%;

    @media (--sm-viewport) {
        margin-top: 10px;
    }

    @media (--md-viewport) {
        margin-top: 1.4vw;
    }

    @media (--lg-viewport) {
        margin-top: 1.2vw;
    }
}

.Thumb-img {
    .Modernizr-js & img:not([style]) {
        opacity: 0;
        transition: opacity 0.6s ease;

        &.is-loaded {
            opacity: 1;
        }
    }
}

.Thumb-img.no-image {
    text-align: center;

    > div {
        align-items: center;
        background-image: linear-gradient(62deg, var(--orange-red), var(--marigold));
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    h3 {
        color: var(--color-text);
        font-size: var(--font-size-lgmd);
        height: auto;
        width: 90%;

        @media (--sm-viewport) {
            font-size: var(--font-size-xxlg);
        }
    }
}
