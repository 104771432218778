.Header {
    backface-visibility: hidden;
    background: var(--light-grey);
    height: 70px;
    left: 0;
    padding-left: var(--gutter);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;

    @media (--sm-viewport) {
        padding-left: var(--gutter-sm);
    }

    @media (--md-viewport) {
        display: flex;
        padding-left: var(--gutter-md);
    }

    .Modernizr-no-js & {
        height: auto;
        position: static;

        @media (--md-viewport) {
            height: 70px;
            position: fixed;
        }
    }
}

.Header-inner {
    width: 100%;

    @media (--md-viewport) {
        display: flex;
        justify-content: space-between;
    }
}

.Header-title {
    line-height: 1;
    padding-top: 16.5px;

    @media (--sm-viewport) {
        padding-top: 19.5px;
    }

    @media (--md-viewport) {
        background-image: linear-gradient(var(--color-home), var(--color-home));
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100% 0;
        padding-top: 21px;
        transition: background-size 0.1s ease-in-out;

        .Modernizr-no-touchevents &:hover {
            background-size: 100% 2px;
        }
    }
}

.Header-logoSm {
    svg {
        height: 16.5px;
        width: 50px;
    }

    @media (--sm-viewport) {
        display: none;
    }
}

.Header-logoMd {
    display: none;

    svg {
        height: 18.7px;
        width: 168px;
    }

    @media (--sm-viewport) {
        display: inline;
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(-12px);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

.Header-nav {
    display: flex;
    margin-right: var(--gutter-md);

    .Modernizr-no-js & {
        margin-right: var(--gutter);

        @media (--sm-viewport) {
            margin-right: var(--gutter-sm);
        }

        @media (--md-viewport) {
            margin-right: var(--gutter-md);
        }
    }
}

.Header-search {
    backface-visibility: hidden;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: scale(1.1);
    transition: opacity 0.2s ease, transform 0.15s ease;
    width: 100%;
    z-index: 20;

    &.is-open {
        opacity: 1;
        pointer-events: all;
        transform: none;
    }
}
