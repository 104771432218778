.Post {
    padding-bottom: 40px;

    @media (--sm-viewport) {
        padding-bottom: 68px;
    }

    @media (--md-viewport) {
        margin-bottom: 40px;
    }
}

.Post-header {
    padding: 0 var(--gutter);

    @media (--sm-viewport) {
        padding: 0 var(--gutter-sm);
    }

    @media (--md-viewport) {
        padding: 0;
    }
}

.Post-date {
    color: var(--med-dark-grey);
    font-size: var(--font-size-xxsm);
    letter-spacing: 0.8px;
}

.Post-title {
    color: var(--black);
    font-family: var(--font-light);
    font-size: var(--font-size-lg);
    font-weight: 200;
    line-height: 1.4;
    margin: 0.4em 0 0.6em;

    @media (--sm-viewport) {
        font-size: var(--font-size-xxlg);
        margin: 4px 0 7px;
    }

    @media (--md-viewport) {
        font-size: var(--font-size-xxxlg);
        line-height: 1.2;
        margin: 15px 0;
    }
}

.Post-tags {
    color: var(--med-dark-grey);
    display: flex;
    flex-wrap: wrap;
    font-size: var(--font-size-xxsm);
    letter-spacing: 0.8px;

    li:not(:last-of-type)::after {
        content: "|";
        margin: 0 0.5em;
    }

    .Modernizr-no-touchevents & a:hover {
        background-image: linear-gradient(var(--med-dark-grey), var(--med-dark-grey));
        background-position: 0 1.3em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        font-weight: normal;
        margin-bottom: -0.4em;
        padding-bottom: 0.4em;
        text-shadow:
            -1px -1px 0 var(--color-bg),
            1px -1px 0 var(--color-bg),
            -1px 1px 0 var(--color-bg),
            1px 1px 0 var(--color-bg);
    }
}

.Post-content {
    margin-top: 26px;
    padding: 0 var(--gutter);

    @media (--sm-viewport) {
        margin-top: 44px;
        padding: 0 var(--margin-post);
    }

    @media (--md-viewport) {
        margin-top: 32px;
    }

    /*img,
    .wp-caption {
        margin-left: calc(0 - var(--gutter)) !important;
        max-width: 100vw;
        width: 100vw !important;

        @media (--sm-viewport) {
            margin-left: calc(0 - var(--margin-post)) !important;
            max-width: var(--max-width-post);
            width: var(--max-width-post) !important;
        }
    }

    .wp-caption img {
        margin: 0;
    }*/
}

.Post-footer {
    border-bottom: solid 1px rgba(155, 154, 155, 0.53);
    display: flex;
    justify-content: flex-end;
    margin: 0 var(--gutter);
    padding: 16px 0;

    @media (--sm-viewport) {
        margin: 0 var(--gutter-sm);
    }

    @media (--md-viewport) {
        margin: 0;
    }
}

.Post-share {
    display: flex;
    font-size: var(--font-size-xxsm);

    li {
        margin-left: 8px;
    }

    svg {
        height: 23px;
        width: 23px;
    }
}
