.Reel {
    background: var(--black);
    height: calc(100vh - var(--nav-height));
    left: 0;
    max-height: calc(100vh - var(--nav-height));
    position: absolute;
    top: var(--nav-height);
    width: 100%;

    @media (--md-viewport) {
        height: var(--ratio-16-9);
    }

    @media (--lg-viewport) {
        backface-visibility: hidden;
        position: fixed;
    }
}

.Reel-video,
.Reel-slideshow {
    display: none;
    width: 100%;
}

.Reel-video {
    height: 100%;
    opacity: 0;
    transition: opacity 1.6s ease;
    width: 100%;

    iframe,
    video {
        height: var(--ratio-16-9);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    &.is-initialized {
        display: block;
    }

    &.is-ready {
        opacity: 1;
    }
}

.Reel-slideshow {
    height: 100%;
    width: 100%;

    &.slick-initialized {
        display: block;
    }

    .slick-list,
    .slick-track {
        height: 100%;
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

@keyframes reel-bounce {
    50% {
        transform: translateX(4px);
    }

    100% {
        transform: translateX(0);
    }
}

.Reel-down {
    backface-visibility: hidden;
    display: flex;
    height: 60px;
    left: 50%;
    position: fixed;
    top: calc(100% - var(--nav-height));
    transform: translate(-50%, 0) rotate(-90deg);
    transition: opacity 0.35s ease, transform 0.35s ease;
    transition-delay: 0.5s;
    width: 60px;
    z-index: 1;

    @media (--md-viewport) {
        bottom: 60px;
        position: absolute;
        top: auto;
    }

    svg {
        fill: var(--white);
        height: 19px;
        left: 50%;
        margin: -10px;
        position: absolute;
        stroke: var(--white);
        stroke-width: 0.5px;
        top: 50%;
        width: 19px;
    }

    &.is-hidden {
        opacity: 0;
        transform: translate(-50%, -80px) rotate(-60deg);
        transition-delay: 0s;
        transition-duration: 0.15s;
    }

    .Modernizr-no-touchevents &:hover svg {
        animation: reel-bounce 0.6s 2;
        animation-timing-function: ease-out-cubic;
    }
}
