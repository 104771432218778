/**
 * Global element defaults
 */

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::selection {
    background: var(--color-text);
    color: var(--color-bg);
    text-shadow: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

button:focus,
a:focus {
    outline: none;
}

button {
    color: inherit;
    cursor: pointer;
    /*font-family: var(--font-normal);*/
    font: inherit;
    user-select: none;
}

table {
    table-layout: fixed;
}

address,
cite {
    font-style: normal;
}

h2 {
    font-size: var(--font-size-lg);
    font-weight: normal;
    line-height: 1.2;

    @media (--md-viewport) {
        font-size: var(--font-size-xxxlg);
    }
}

hr {
    border: 0;
    border-bottom: solid 2px var(--color-work);
    margin: 1em 0;
    width: 100%;
}
