.Breadcrumbs {
    /*border: 1px dashed green;*/
    align-items: center;
    color: var(--med-grey);
    display: flex;
    font-family: var(--font-light);
    font-size: var(--font-size-lgmd);
    height: var(--nav-height);
    left: 63px;
    margin-left: var(--gutter);
    margin-top: 1px;
    position: absolute;
    top: 0;

    @media (--sm-viewport) {
        left: 180px;
        margin-left: var(--gutter-sm);
    }

    @media (--md-viewport) {
        display: none;
    }

    .Page:not(.is-single) & {
        svg,
        li {
            animation: slide-in 0.6s ease forwards 0.8s;
            opacity: 0;
        }

        li {
            animation-delay: 1.2s;
        }
    }

    svg {
        margin-right: 12px;
        /*margin-top: 1px;*/
        stroke-width: 2;

        .Page--home & {
            display: none;
        }

        .Page--work & {
            stroke: var(--color-work);
        }

        .Page--about & {
            stroke: var(--color-about);
        }

        .Page--jobs & {
            stroke: var(--color-jobs);
        }

        .Page--blog & {
            stroke: var(--color-blog);
        }

        .Page--culture & {
            stroke: var(--color-culture);
        }

        .Page--contact & {
            stroke: var(--color-contact);
        }
    }
}

.Breadcrumbs-item {
    display: none;

    &.is-current:not(.Breadcrumbs-item--home),
    .Page--work &.Breadcrumbs-item--work,
    .Page--jobs &.Breadcrumbs-item--jobs,
    .Page--culture &.Breadcrumbs-item--culture,
    .Page--blog &.Breadcrumbs-item--blog {
        display: block;
    }
}
