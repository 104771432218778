.Ticker {
    background: var(--color-home);
    overflow: hidden;
    padding-bottom: 40px;
    position: relative;

    @media (--sm-viewport) {
        padding-bottom: 78px;
    }

    @media (--md-viewport) {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 66px 0 54px;
    }

    &.Ticker--home {
        background: var(--color-home);
    }

    &.Ticker--work {
        background: var(--color-work);
    }

    &.Ticker--about {
        background: var(--color-about);
        color: var(--white);
    }

    &.Ticker--jobs {
        background: var(--color-jobs);
    }

    &.Ticker--culture {
        background: var(--color-culture);
        color: var(--white);
    }

    &.Ticker--error404 {
        background: var(--color-home);
    }
}

.Ticker-carousel {
    /*border: 1px dashed green;*/
    flex: none;
    overflow: hidden;
    width: 100vw;

    @media (--sm-viewport) {
        margin-left: var(--gutter-sm);
        width: calc(100vw - var(--gutter-sm) * 2);
    }

    @media (--md-viewport) {
        margin-left: 0;
        width: calc(100vw - var(--gutter-md) * 2);
    }
}

.Ticker-carouselInner {
    width: 100vw;

    @media (--sm-viewport) {
        width: calc(103vw - var(--gutter-sm) * 2);
    }

    @media (--md-viewport) {
        width: calc(102vw - var(--gutter-md) * 2);
    }

    .Modernizr-js & {
        visibility: hidden;
    }

    .Modernizr-no-js & {
        display: flex;
        flex-wrap: wrap;
    }

    &.slick-initialized {
        visibility: visible;
    }
}

.Ticker-item {
    /*border: 1px dashed blue;*/
    margin-left: var(--gutter);
    margin-right: var(--gutter);
    position: relative;
    width: calc(100vw - var(--gutter) * 2);

    @media (--sm-viewport) {
        margin-left: 0;
        margin-right: 3vw;
        width: 48.5vw;
    }

    @media (--md-viewport) {
        margin-right: 2vw;
        margin-top: 20px;
        /*width: 32vw;*/
        width: 28.3vw;
    }

    a {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        text-indent: -9999px;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .Modernizr-js &[data-slick-index="0"],
    .Modernizr-js &[data-slick-index="1"],
    .Modernizr-js &[data-slick-index="2"] {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.8s ease-out-cubic 0.1s, transform 0.9s ease-out-cubic;
        transition-delay: 0.3s;
    }

    &[data-slick-index="1"] {
        transition-delay: 0.5s;
    }

    &[data-slick-index="2"] {
        transition-delay: 0.7s;
    }

    .is-visible .slick-initialized & {
        opacity: 1;
        transform: none;
    }
}

.Ticker-itemImg.no-image {
    background: linear-gradient(135deg, var(--bright-orange) 0%, var(--marigold) 100%);
    text-align: center;

    > div {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    h2 {
        color: var(--white);
        font-size: var(--font-size-md);
        height: auto;
        width: 90%;

        @media (--lg-viewport) {
            font-size: var(--font-size-lgmd);
        }
    }
}

.Ticker-itemInfo {
    margin-top: 20px;
    width: 100%;

    h2 {
        font-family: var(--font-light);
        font-size: var(--font-size-mdlg);
        line-height: 1.4;
    }

    p {
        font-family: var(--font-light);
        line-height: 1.3;
    }

    .Ticker--work &,
    .Ticker--error404 & {
        h2 {
            font-family: var(--font-normal);
        }

        h2,
        p {
            font-size: var(--font-size-sm);
            letter-spacing: 1px;
            margin-bottom: 0.6em;
        }
    }
}

.Ticker-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    @media (--sm-viewport) {
        padding: 14px 0 6px;
    }

    @media (--md-viewport) {
        height: 100%;
        left: 0;
        padding: 20px 0 30px;
        position: absolute;
        top: 0;
    }
}

.Ticker-navTitle {
    color: inherit;
    font-size: var(--font-size-xsm);
    letter-spacing: 1px;
    transition: opacity 0.6s ease-out-cubic 0.5s, transform 0.7s ease-out-cubic 0.6s;

    @media (--md-viewport) {
        font-size: var(--font-size-sm);
        left: var(--gutter-md);
        position: absolute;
        top: 46px;
    }

    .Modernizr-js & {
        opacity: 0;
        transform: translateX(40px);
    }

    .active &,
    .is-visible &,
    &.is-visible {
        opacity: 1;
        transform: none;
    }
}

.Ticker-prev,
.Ticker-next {
    /*border: 1px dashed red;*/
    line-height: 0;
    margin: var(--gutter);
    transition: opacity 0.6s ease-out-cubic 0.5s, transform 0.7s ease-out-cubic 0.6s;

    @media (--sm-viewport) {
        margin: var(--gutter-sm);
    }

    @media (--md-viewport) {
        margin: 30px 26px;
    }

    .Modernizr-js & {
        opacity: 0;
        transform: translateX(100px);
    }

    .Modernizr-no-js & {
        display: none;
    }

    .Modernizr-js .active &,
    .Modernizr-js .is-visible &,
    &.is-visible {
        opacity: 1;
        transform: none;
    }

    &.slick-hidden {
        opacity: 0;
    }

    svg {
        fill: var(--black);
        height: 19px;
        width: 19px;

        .Ticker--about &,
        .Ticker--culture &,
        .Ticker--jobs & {
            fill: var(--white);
        }
    }

    .Modernizr-no-touchevents &:hover {
        animation: bounce 0.6s 2;
        animation-timing-function: ease-out-cubic;
    }
}

.Ticker-next {
    .Modernizr-js & {
        transform: translateX(-100px);
    }

    svg {
        transform: scaleX(-1);
    }
}
