.Clients {
    /*border: 1px dashed red;*/
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -9%;
    width: 118%;

    @media (--md-viewport) {
        margin-left: -4%;
        width: 108%;
    }
}

.Clients-item {
    /*border: 1px dashed green;*/
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 4% 0;
    min-height: 80px;
    width: 50%;

    @media (--sm-viewport) {
        width: 33.3%;
    }

    @media (--md-viewport) {
        width: 25%;
    }
}

.Copy .Clients-img {
    display: block;
    height: auto;
    max-height: 70px;
    max-width: 120px;
    min-width: 0;
    width: auto;
}
