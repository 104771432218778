.Content {
    margin: 0 auto;
    width: 100%;

    .Page--error404 & {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        padding-top: 60px;

        @media (--sm-viewport) {
            padding-top: 100px;
        }
    }
}

.Content-body {
    margin: 0 auto;
    margin-bottom: 3em;
    max-width: var(--max-width-main);
    padding: 0 var(--gutter);
    position: relative;
    width: 100%;

    @media (--sm-viewport) {
        margin-bottom: 6em;
        padding: 0 calc(var(--gutter-sm) + 58px);
    }

    @media (--md-viewport) {
        padding: 0 var(--gutter-md);
    }

    .About &,
    .Culture &,
    .Jobs & {
        max-width: var(--max-width-post);
        padding: 0 var(--gutter);

        @media (--sm-viewport) {
            padding: 0 var(--margin-post);
        }

    }

    .Culture &,
    .Jobs & {
        img,
        .wp-caption {
            margin-left: calc(0 - var(--gutter));
            max-width: var(--max-width-post);
            width: 100vw;

            @media (--sm-viewport) {
                margin-left: calc(0 - var(--margin-post));
                width: var(--max-width-post);
            }
        }

        .wp-caption img {
            margin: 0;
        }

        .wp-caption-text {
            margin: var(--gutter);

            @media (--sm-viewport) {
                margin: 10px var(--margin-post);
            }

            @media (--md-viewport) {
                margin: 10px 0;
            }
        }
    }

    .Page--error404 & {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        justify-content: center;
    }
}

.Content-title {
    margin: 1em 0 2em;

    @media (--sm-viewport) {
        margin: 40px 0;
        padding-top: 12px;
    }

    @media (--md-viewport) {
        padding-top: 6px;
    }

    h2 {
        font-family: var(--font-light);
        font-size: var(--font-size-xlg);

        @media (--md-viewport) {
            font-size: var(--font-size-xxxlg);
        }
    }
}

.Content-workDescription {
    margin-bottom: 20px;

    @media (--md-viewport) {
        display: flex;
        justify-content: flex-start;
    }

    h3 {
        font-family: var(--font-normal);
        font-size: var(--font-size-xsm);
        letter-spacing: 1px;
        margin-bottom: 1em;

        @media (--md-viewport) {
            font-size: var(--font-size-sm);
            line-height: 2.2;
            width: 17%;
        }
    }

    > div {

        @media (--md-viewport) {
            width: 66%;
        }
    }
}
