/**
 * Utils. Naming convention is .u-utilityName
 */

.u-isHiddenVisually {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

/* extend hit area of buttons/links */

.u-extendHitarea {
    position: relative;

    &::before {
        /*background-color: rgba(255, 0, 0, 0.3);*/
        content: "";
        height: 200%;
        left: -20%;
        position: absolute;
        top: -50%;
        width: 140%;
    }
}

.u-centerChild {
    align-items: center;
    display: flex;
    justify-content: center;

    > * {
        flex: 0 0 auto;
    }
}

.u-absCenterChild {
    position: relative;

    > * {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.u-absCenter {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.u-absCenterX {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.u-absCenterY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.u-mediaContainer {
    display: block;
    position: relative;
    width: 100%;

    > img,
    > video {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &::before {
        content: "";
        display: block;
        padding-bottom: 56.25%;
        width: 100%;
    }

    &.u-ratio-10-4::before {
        padding-bottom: 40%;
    }

    &.u-ratio-16-9::before {
        padding-bottom: 56.25%;
    }

    &.u-ratio-3-2::before {
        padding-bottom: 66.666%;
    }

    &.u-ratio-1-1::before {
        padding-bottom: 100%;
    }
}

.Modernizr-js .u-scrollShow {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
    transition: opacity 0.6s ease-in-out-cubic 0.1s, transform 0.85s ease-in-out-cubic;
    visibility: hidden;

    &.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
}

@keyframes bounce {
    50% {
        transform: translateX(4px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounce-flip {
    50% {
        transform: scaleX(-1) translateX(4px);
    }

    100% {
        transform: scaleX(-1) translateX(0);
    }
}

.u-pageBgColor {
    .Page--home & {
        background: var(--color-home);
    }

    .Page--work & {
        background: var(--color-work);
    }

    .Page--about & {
        background: var(--color-about);
    }

    .Page--jobs & {
        background: var(--color-jobs);
    }

    .Page--blog & {
        background: var(--color-blog);
    }

    .Page--culture & {
        background: var(--color-culture);
    }

    .Page--contact & {
        background: var(--color-contact);
    }
}

.u-textTruncate {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}
