.Nav {
    backface-visibility: hidden;
    height: calc(100% - var(--nav-height));
    left: 0;
    margin-top: var(--nav-height);
    overflow-y: auto;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @media (--md-viewport) {
        display: flex;
        height: auto;
        margin-top: 8px;
        pointer-events: all;
        position: static;
        width: auto;
    }

    &.is-open {
        pointer-events: all;
    }

    .Modernizr-no-js & {
        position: static;
    }
}

.Nav.is-open .Nav-item {
    transform: none;
}

.Nav-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 385px;

    @media (--md-viewport) {
        flex-direction: row;
        height: auto;
        min-height: 0;
    }
}

.Nav-item {
    display: flex;
    flex-grow: 1;
    min-height: 45px;
    transform: translateX(100%);
    transition: transform 0.4s;
    transition-timing-function: ease-in-out-circ;
    transition-timing-function: ease-in-out-sine;
    transition-timing-function: ease-in-out-cubic;

    &.Nav-item--home {
        background-image: linear-gradient(var(--color-home), var(--color-home));
    }

    &.Nav-item--work {
        background-image: linear-gradient(var(--color-work), var(--color-work));
    }

    &.Nav-item--about {
        background-image: linear-gradient(var(--color-about), var(--color-about));
    }

    &.Nav-item--jobs {
        background-image: linear-gradient(var(--color-jobs), var(--color-jobs));
    }

    &.Nav-item--blog {
        background-image: linear-gradient(var(--color-blog), var(--color-blog));
    }

    &.Nav-item--culture {
        background-image: linear-gradient(var(--color-culture), var(--color-culture));
    }

    &.Nav-item--contact {
        background-image: linear-gradient(var(--color-contact), var(--color-contact));
    }

    &:first-child {
        transition-delay: 0s;
    }

    &:nth-child(2) {
        transition-delay: 0.05s;
    }

    &:nth-child(3) {
        transition-delay: 0.1s;
    }

    &:nth-child(4) {
        transition-delay: 0.15s;
    }

    &:nth-child(5) {
        transition-delay: 0.2s;
    }

    &:nth-child(6) {
        transition-delay: 0.25s;
    }

    &:nth-child(7) {
        transition-delay: 0.3s;
    }

    @media (--md-viewport) {
        background-position: 10px 100%;
        background-repeat: no-repeat;
        background-size: calc(100% - 20px) 0;
        flex: none;
        position: relative;
        transform: none;
        transition: background-size 0.1s ease-in-out;
        transition-delay: 0s !important;

        .Modernizr-no-touchevents &:hover,
        &.is-current,
        .Page--blog &.Nav-item--blog,
        .Page--jobs &.Nav-item--jobs,
        .Page--culture &.Nav-item--culture,
        .Page--work &.Nav-item--work {
            background-size: calc(100% - 20px) 2px;
        }
    }

    &:first-of-type {

        @media (--md-viewport) {
            display: none;
        }
    }

    &::after {

        @media (--md-viewport) {
            background: var(--grey);
            content: "";
            height: 24px;
            position: absolute;
            right: 0;
            top: 20px;
            width: 1px;
        }
    }

    .Modernizr-no-js & {
        transform: none;
    }
}

.Nav-item a {
    align-items: center;
    color: var(--white);
    display: flex;
    font-family: var(--font-light);
    font-size: var(--font-size-xlg);
    padding: 0 var(--gutter);
    width: 100%;

    @media (--sm-viewport) {
        align-items: center;
        font-size: var(--font-size-xxlg);
        padding: 28px var(--gutter-sm);
    }

    @media (--md-viewport) {
        color: var(--black);
        font-size: var(--font-size-sm);
        padding: 4px 22px;
    }

    @media (--min-landscape) {
        font-size: var(--font-size-lg);
    }
}

.Nav-search {
    margin-right: 20px;
    margin-top: 3px;
    position: absolute;
    right: calc(var(--gutter) * 2);
    top: 50%;
    transform: translateY(-50%);

    @media (--sm-viewport) {
        margin-right: 4px;
        right: calc(var(--gutter-sm) * 2);
    }

    @media (--md-viewport) {
        margin-left: 20px;
        margin-right: 0;
        margin-top: 14px;
        position: static;
        transform: none;
    }

    .Modernizr-no-js & {
        display: none;
    }
}
