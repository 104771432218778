@keyframes bounce-up {
    50% {
        transform: translate(-50%, calc(-50% - 4px));
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

.Top {
    display: block;
    height: 40px;
    width: 50px;

    svg {
        fill: var(--white);

        .Page--home &,
        .Page--work &,
        .Page--about & {
            fill: var(--black);
        }
    }

    .Modernizr-no-touchevents &:hover svg {
        animation: bounce-up 0.6s 2;
        animation-timing-function: ease-out-cubic;
    }
}
