/**
 * HTML section defaults
 */

html {
    /*background-color: var(--black);*/
    background-color: var(--light-grey);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
}

body {
    background-color: var(--color-bg);
    color: var(--color-text);
    font-family: var(--font-normal), sans-serif;
    height: 100%;
    /*letter-spacing: 0.025em;*/
    line-height: 1.5;
    margin: 0;
    min-height: 100%;
    overflow-y: scroll;
    padding: 0;
    text-size-adjust: none;
    touch-callout: none;
}

.Page {
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    overflow-x: hidden;

    > main {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        margin-top: var(--nav-height);
    }

    > footer {
        align-self: flex-end;
    }
}
