:root {
    --browse-height: 50px;
}

.Browse {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 30px;
    overflow: hidden;
    padding: 0 var(--gutter);
    pointer-events: none;
    width: 100%;

    @media (--sm-viewport) {
        backface-visibility: hidden;
        left: 0;
        margin: 0;
        margin-top: calc(var(--nav-height) + 40px);
        padding: 0;
        position: fixed;
        top: var(--hero-height);
        transform: translate3d(0, 0, 0);
        width: 100%;
        z-index: 1;
    }

    @media (--sm-viewport) {
        top: var(--hero-height-sm);
        transition: top 0.4s ease;

        &.hero-expanded {
            position: absolute;
            top: var(--hero-height);
        }

        &.hero-expanded.is-fixed {
            position: fixed;
            top: var(--hero-height-sm);
        }
    }

    @media (--md-viewport) {
        top: var(--hero-height-md);
        transition: top 0.4s ease;

        &.hero-expanded {
            position: absolute;
            top: var(--hero-height);
        }

        &.hero-expanded.is-fixed {
            position: fixed;
            top: var(--hero-height-md);
        }
    }
}

.Browse--hero-none {

    @media (--sm-viewport) {
        top: 0;
    }
}

.Browse-title {
    font-size: var(--font-size-xsm);
    opacity: 0;
    transition: opacity 0.6s ease 0.7s;

    @media (--sm-viewport) {
        display: none;
    }

    .is-visible & {
        opacity: 1;
    }
}

.Browse-prev,
.Browse-next {
    line-height: 0;
    pointer-events: all;
    transition: transform 0.4s ease 1s;

    @media (--min-viewport) {
        background: none !important;
    }

    @media (--sm-viewport) {
        align-items: center;
        display: flex;
        height: var(--browse-height);
        justify-content: center;
        postion: relative;
        width: 40px;

        &::before {
            display: none;
        }
    }

    svg {
        fill: var(--color-work);
        stroke: var(--color-work);
        stroke-width: 0.5;

        @media (--min-viewport) {
            .Work & {
                fill: var(--color-work);
                stroke: var(--color-work);
            }

            .Jobs & {
                fill: var(--color-jobs);
                stroke: var(--color-jobs);
            }

            .Blog & {
                fill: var(--color-blog);
                stroke: var(--color-blog);
            }

            .Culture & {
                fill: var(--color-culture);
                stroke: var(--color-culture);
            }
        }

        @media (--sm-viewport) {
            fill: var(--white);
            height: 17px;
            stroke: var(--white);
            stroke-width: 0.5;
            width: 17px;
        }
    }

    &.is-disabled {
        visibility: hidden;
    }

    .is-visible & {
        transform: none;
    }

    .is-sticky & {
        transition-delay: 0s;
        transition-duration: 0.15s;
    }

    .Modernizr-no-touchevents &:hover {
        .Browse-postTitle {
            transform: none;

            span {
                opacity: 1;
            }
        }

        svg {
            animation: bounce 0.6s 2;
            animation-timing-function: ease-out-cubic;
        }
    }
}

.Browse-prev {
    transform: translate3d(-220%, 0, 0);

    @media (--sm-viewport) {
        transform: translate3d(-100%, 0, 0);
    }
}

.Browse-next {
    transform: translate3d(220%, 0, 0);

    @media (--sm-viewport) {
        transform: translate3d(100%, 0, 0);
    }

    svg {
        transform: scaleX(-1);
    }

    .Modernizr-no-touchevents &:hover svg {
        animation-name: bounce-flip;
    }
}

.Browse-postTitle {
    display: none;

    @media (--sm-viewport) {
        color: var(--white);
        display: inline-block;
        font-size: var(--font-size-sm);
        height: var(--browse-height);
        letter-spacing: 1px;
        line-height: var(--browse-height);
        line-height: normal;
        padding: 0 30px;
        position: absolute;
        right: 100%;
        top: 0;
        transform: translateX(100%);
        transition: transform 0.15s ease;
        white-space: nowrap;
        z-index: -1;

        span {
            align-items: center;
            display: flex;
            height: 100%;
            opacity: 0;
            transition: opacity 0.15s ease;
        }
    }
}

.Browse-prev .Browse-postTitle {

    @media (--sm-viewport) {
        left: 100%;
        right: auto;
        transform: translateX(-100%);
    }
}
