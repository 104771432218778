.Blog {
    margin: 0 auto;
    width: 100%;
}

.Blog-inner {
    position: relative;

    @media (--sm-viewport) {
        display: flex;
        justify-content: center;
    }

    @media (--md-viewport) {
        padding: 0 var(--gutter-md);
    }
}

.Blog-posts {
    max-width: var(--max-width-post);
    width: 100%;
}

.Blog-header {
    padding-bottom: 40px;
    width: 100%;
}

.Blog-nextLink {
    padding: 60px;

    .Modernizr-js & {
        display: none;
    }
}

.Blog-sidebar {
    width: 100%;
}

.Blog-backToTop {
    bottom: 0;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0);
    transition: transform 0.2s ease;

    &.is-hidden {
        transform: translate(-50%, 100%);
    }
}
