:root {
    /*--contact-address-width-sm: 33.2%;*/
    --contact-address-width-sm: 38%;
    --contact-address-width-md: 28.5%;
    --contact-address-width-lg: 26.6%;
    --contact-address-width-xlg: 26.2%;
}

.Contact {
    margin-bottom: 60px;
    width: 100%;
}

.Contact-location {

    @media (--sm-viewport) {
        display: flex;
    }

    @media (--min-landscape) {
        display: flex;
    }

    address,
    iframe {
        display: block;
        width: 100%;

        @media (--sm-viewport) {
            flex: none;
        }

        @media (--min-landscape) {
            flex: none;
            width: 50%;
        }
    }

    address {

        @media (--sm-viewport) {
            width: var(--contact-address-width-sm);
        }

        @media (--md-viewport) {
            width: var(--contact-address-width-md);
        }

        @media (--lg-viewport) {
            width: var(--contact-address-width-lg);
        }

        @media (--xlg-viewport) {
            width: var(--contact-address-width-xlg);
        }
    }

    iframe {
        height: var(--hero-height);

        @media (--sm-viewport) {
            height: var(--hero-height-sm);
            width: calc(100% - var(--contact-address-width-sm));
        }

        @media (--md-viewport) {
            height: var(--hero-height-md-contact);
            width: calc(100% - var(--contact-address-width-md));
        }

        @media (--lg-viewport) {
            width: calc(100% - var(--contact-address-width-lg));
        }

        @media (--xlg-viewport) {
            width: calc(100% - var(--contact-address-width-xlg));
        }

        @media (--min-landscape) {
            height: auto;
        }
    }
}

.Contact-address {
    color: var(--white);
    font-size: var(--font-size-lg);
    line-height: 1.2;
    padding: var(--gutter);

    @media (--sm-viewport) {
        /*font-size: var(--font-size-mdlg);*/
        order: -1;
        padding: var(--gutter-sm);
    }

    @media (--md-viewport) {
        font-size: 1.6vw;
        padding: var(--gutter-md);
        padding-right: var(--gutter);
    }

    @media (--lg-viewport) {
        font-size: 1.9vw;
    }

    @media (--xlg-viewport) {
        /*font-size: var(--font-size-xlgx);*/
    }

    @media (--min-landscape) {
        order: -1;
    }

    br:last-child {
        line-height: 1.5;

        @media (--sm-viewport) {
            line-height: 2.5;
        }
    }
}

.Contact-list {
    margin: 26px var(--gutter) 0;

    @media (--sm-viewport) {
        margin: 40px var(--gutter-sm) 0;
    }

    @media (--md-viewport) {
        margin: 58px var(--gutter-md) 0;
    }

    ul {

        @media (--sm-viewport) {
            display: flex;
            flex-wrap: wrap;
        }

        @media (--md-viewport) {
            flex-wrap: nowrap;
        }
    }
}

.Contact-item {
    flex: none;
    padding: 0 0 30px;
    position: relative;
    width: 100%;

    @media (--sm-viewport) {
        margin-bottom: 30px;
        padding: 0 20px 0 0;
        width: 33.3%;
    }

    @media (--md-viewport) {
        flex: 1 1 auto;
        padding: 0 10px 0 0;
        width: auto;
    }

    &:not(:last-of-type)::after {
        background: #d8d8d8;
        bottom: 15px;
        content: "";
        height: 1px;
        position: absolute;
        right: 0;
        width: 100%;

        @media (--sm-viewport) {
            bottom: auto;
            height: 80%;
            right: 15px;
            top: 10%;
            width: 1px;
        }

        @media (--md-viewport) {
            right: 11px;
        }

        @media (--lg-viewport) {
            right: 28px;
        }
    }

    &:nth-of-type(3n+3)::after {

        @media (--sm-viewport) {
            display: none;
        }

        @media (--md-viewport) {
            display: block;
        }
    }

    h2 {
        font-family: var(--font-light);
        font-size: var(--font-size-lg);
        line-height: 1.2;
        margin-bottom: 10px;

        @media (--md-viewport) {
            font-size: var(--font-size-xlg);
        }
    }

    h3 {
        font-size: var(--font-size-xsm);
        letter-spacing: 1px;
        line-height: 1.6;
        margin-bottom: 5px;

        @media (--md-viewport) {
            font-size: var(--font-size-sm);
            margin-bottom: 10px;
        }
    }

    a {
        display: block;
        font-size: var(--font-size-xsm);
        letter-spacing: 1px;
        line-height: 1.5;

        @media (--md-viewport) {
            font-family: var(--font-light);
            font-size: var(--font-size-sm);
            line-height: 1.3;
        }
    }

    .Modernizr-no-touchevents & a[href*="mailto:"]:hover span {
        background-image: linear-gradient(var(--color-text), var(--color-text));
        background-position: 0 1.2em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        margin-bottom: -0.2em;
        padding-bottom: 0.2em;
        text-shadow:
            -1px -1px 0 var(--color-bg),
            1px -1px 0 var(--color-bg),
            -1px 1px 0 var(--color-bg),
            1px 1px 0 var(--color-bg);
    }
}
