.Close {
    font-size: 0;
    height: 44px;
    margin: -12px;
    position: relative;
    text-indent: -9999px;
    width: 44px;

    &::before,
    &::after {
        background: var(--white);
        content: "";
        height: 2px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 20px;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(135deg);
    }

    .Modernizr-no-touchevents &:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease;
    }
}
