.Search {
    background: var(--black);
    color: var(--white);
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;

    @media (--lg-viewport) {
        overflow: hidden;
    }

    ::selection {
        background: rgba(255, 255, 255, 0.99);
        color: var(--black);
    }
}

.Search-inner {
    padding: 23px var(--gutter);
    width: 100%;

    @media (--sm-viewport) {
        padding: 23px var(--gutter-sm);
    }

    @media (--md-viewport) {
        padding: 30px var(--gutter-md);
    }
}

.Search-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    width: 100%;

    @media (--md-viewport) {
        margin-bottom: 155px;
    }
}

.Search-form {
    margin: 0;
    width: 100%;
}

.Search-input {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: solid 1px #d8d8d8;
    border-radius: 0;
    color: inherit;
    font-family: var(--font-light);
    font-size: var(--font-size-xxxxlg);
    line-height: 1.2;
    padding: 0;
    padding-bottom: 6px;
    width: 100%;

    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.Search-results {
    margin-top: 45px;

    @media (--md-viewport) {
        margin-top: 90px;
    }

    @media (--lg-viewport) {
        max-height: calc(100vh - 400px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.Search-resultsList {
    margin-bottom: 60px;

    @media (--sm-viewport) {
        margin-bottom: 30px;
    }

    @media (--lg-viewport) {
        margin-bottom: 0;
    }
}

.Search-header h2 {
    font-family: var(--font-light);
}

.Search-header h2,
.Search-resultsList li {
    font-size: var(--font-size-sm);
    letter-spacing: 1px;
    line-height: 1.9;

    time {
        display: inline-block;
        line-height: 1.3;
        margin-right: 22px;
        min-width: 92px;

        @media (--sm-viewport) {
            margin-right: 43px;
        }

        @media (--md-viewport) {
            margin-right: 86px;
        }
    }

    .Modernizr-no-touchevents & a:hover time,
    .Modernizr-no-touchevents & a:hover span {
        background-image: linear-gradient(var(--white), var(--white));
        background-position: 0 1.15em;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        margin-bottom: -0.4em;
        padding-bottom: 0.4em;
        text-shadow:
            -1px -1px 0 var(--black),
            1px -1px 0 var(--black),
            -1px 1px 0 var(--black),
            1px 1px 0 var(--black);
    }
}

.Search-resultsList li {
    opacity: 0;
    transform: translate(0, -15px);
    transition: opacity 0.15s ease-out-cubic, transform 2s ease-out-cubic;
}

.Search-resultsList.is-visible li {
    opacity: 1;
    transform: none;
    transition: opacity 0.2s ease-out-cubic, transform 0.2s ease-out-cubic;
}

.Search-resultsCategory {
    background-image: linear-gradient(var(--color-home), var(--color-home));
    background-position: 0 1.8em;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    font-size: var(--font-size-xlg) !important;
    margin-bottom: 0.8em;

    h3 {
        font-family: var(--font-light);
        font-size: inherit;
    }

    &:not(:first-child) {
        margin-top: 35px;

        @media (--md-viewport) {
            margin-top: 70px;
        }
    }

    &.work {
        background-image: linear-gradient(var(--color-work), var(--color-work));
    }

    &.culture {
        background-image: linear-gradient(var(--color-culture), var(--color-culture));
    }

    &.post {
        background-image: linear-gradient(var(--color-blog), var(--color-blog));
    }
}

.Search-closeBtn {
    top: 3px;
}
